/** imports */
import * as React from 'react'
// import { styled } from '@mui/material/styles';

import { getValuesFromCookieToInputs } from 'tsRoot/actions/cookie'
import { years } from 'tsRoot/actions/years'
// validator
import { checkValidation } from 'tsRoot/validator/validatorBase'
// material-ui
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import TextField from '@mui/material/TextField'

import { format, isValid, subYears } from 'date-fns'
import ja from 'date-fns/locale/ja'

import Rolldate from 'rolldate'
import $ from 'jquery'

/** ファイル固有の型定義 */
// material-uiのButtonのinterfaceからextendsしているが用途が異なるものや
// 推奨パラメータに関しては明示的に記載する
interface Props {
  pageName: string, // cookieの干渉を避けるためにページ名を入れる
  errorMessageClass: string,
  errorFlug: string,
  errorCheckEnable?: boolean,
  validationRule: any
  errorCheck: any
  className?: string,
  inputName: string,
  placeholder?: string,
  error?: boolean,
  popItem?: React.ReactNode,
  value?: any,
  gaName?: string,
  defaultYear?: string,
  label?: string
  require?: boolean
}

/** エクスポートするJSXのコンポーネント */
export const Birthday = (props: Props) => {
  const initValue = getValuesFromCookieToInputs(props.inputName, props.pageName)
  const [value, setValue] = React.useState(initValue || null)
  const [dateStringState, setSateStringState] = React.useState(null)

  const is_changed_value = React.useRef(initValue ? true : false) // 値が画面描画後から一度でも変更されたことがあるかのフラグ
  const already_blur = React.useRef(initValue ? true : false)     // フォームが一度選択された後にフォーカスが外れたことがあるかのフラグ
  const [focused, setFocused] = React.useState(false)
  React.useEffect(() => {
    $(document).ready(function(){
      $("#birthdaySp").attr('readonly', 'readonly');

      new Rolldate({
        el: '#birthdaySp',
        format: "YYYY/MM/DD",
        beginYear: years.startYear,
        endYear: years.endYear,
        displayYear: years.defaultYear,
        trigger: 'click',
        value: value,
        //value: `${defaultYear}年1月1日`,
        lang: {title: '生年月日', cancel: '閉じる', confirm: '完了'},
        confirm: function(date: any) {
          is_changed_value.current = true
          setValue(date)
          document.cookie = `${props.pageName ? `${props.pageName}-` : ''}${props.inputName}=${encodeURIComponent(date)}; max-age=172800; path=/`
        },
      });
    });

    $(window).on('load resize', function(){
      $('.pop-item').each(function(index) {
        // @ts-ignore
        var popItem = $(this).outerHeight(true) + 6;
        $(this).css('top', '-' + popItem + 'px');
      });
    });
  }, []);

  const validateErrorMessage = () => {
    return (
      validateErrorMessageArray.map((message: string, idx: number) => {
        if(idx == 0) return <span className={`${props.errorMessageClass}`} key={`errorMessage${idx}`}>{message}</span>

        return <span className={`${props.errorMessageClass}`} key={`errorMessage${idx}`}><br />{message}</span>
      })
    )
  }

  const handleChange = (date: any, dateString: any) => {
    const valueString = isValid(date) ? format(date, 'yyyy/MM/dd', { locale: ja }) : ''
    setValue(valueString)
    setSateStringState(dateString)

    document.cookie = `${props.pageName ? `${props.pageName}-` : ''}${props.inputName}=${encodeURIComponent(valueString)}; max-age=172800; path=/`
    props.errorCheck()
    is_changed_value.current = true
  };

  const handleBlur = () => {
    already_blur.current = true
    setFocused(false)
  };

  const validateErrorMessageArray = props.errorCheckEnable || is_changed_value.current ? checkValidation(value || dateStringState, props.validationRule) : []
  const is_error = props.errorCheckEnable || (already_blur.current && (props.error || validateErrorMessageArray.length > 0))

  const isComplete = !is_error && already_blur.current && is_changed_value.current

  return (
    <>
      { props.label ?
        <label htmlFor="" className="c-formItem__label" style={{marginBottom: 5}}>
          <span>{props.label}</span>
          {props.require ? <span className={`c-formItem__required ${isComplete ? 'label-valid' : ''}`}>必須{isComplete ? ' ✔︎' : ''}</span> : <></> }
        </label> : <></>
      }
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja} sx={{ display: { xl: 'none', xs: 'block' } }}>
        <div className={'lg:u-hidden'} onBlur={() => {handleBlur()}} onFocus={() => {setFocused(true)}}>
          <DatePicker
            disableFuture
            className={`${props.className} ${is_error ? props.errorFlug : ''} ga-${props.inputName}`}
            openTo="year"
            views={['year', 'month', 'day']}
            value={value}
            onChange={(date: any, dateString: any) => handleChange(date, dateString)}
            inputFormat={'yyyy/MM/dd'}
            minDate={subYears(new Date(2021, 0, 1), 100)}
            maxDate={subYears(new Date(2021, 0, 1), 15)}
            onAccept={()=>{is_changed_value.current = true}}
            renderInput={(params) =>
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: '1988/01/01',
                  onBlur: () => {handleBlur()},
                  style: {
                    height: '14px',
                    padding: '12px 18px',
                    width: '100%'
                  }
                }}
              />
            }
          />
          {props.popItem ?
            <div className={`c-contact__mess__box --top pop_${props.inputName}`} style={focused ? {opacity: 1, top: -40} : {}}>
              <p>{props.popItem}</p>
              <span></span>
            </div> : <></>
          }
        </div>
      </LocalizationProvider>
      <div className={'u-hidden lg:u-block'}>
        <input
          id={'birthdaySp'}
          // required=""
          type="text"
          className={`${props.className} ${is_error ? props.errorFlug : ''} ga_${props.pageName ? `${props.pageName}-` : ''}${props.inputName}_${props.inputName}`}
          spellCheck={false}
          data-ms-editor={true}
          onChange={e => handleChange(props.inputName, e)}
          onBlur={() => handleBlur()}
          value={props.value}
          placeholder={'選択してください'}
        />
      </div>
      {is_error ? validateErrorMessage() : <></>}
    </>
  );
}

